import http from "../utils/http";
import { stringify } from 'qs';

export function deliiveryType(params) {
    return http.get("/zdelivery/deliiveryType", stringify(params));
}

export function underInsurance(params) {
    return http.get("/insurance/underInsurance", stringify(params));
}

export function uninsured(params) {
    return http.get("/insurance/uninsured", stringify(params));
}