<template>
  <div class="home">
    <el-row>
      <el-col :xs="24" :sm="12" :md="12" :lg="6" style="padding: 20px 10px;">
        <div class="count">
          <div class="content">
            <div>全职人员统计</div>
            <div>{{ fullTime }}</div>
          </div>
        </div>
      </el-col>
      <el-col :xs="24" :sm="12" :md="12" :lg="6" style="padding: 20px 10px;">
        <div class="count">
          <div class="content">
            <div>兼职人员统计</div>
            <div>{{ partTime }}</div>
          </div>
        </div>
      </el-col>
      <el-col :xs="24" :sm="12" :md="12" :lg="6" style="padding: 20px 10px;">
        <div class="count">
          <div class="content">
            <div>保险在保人员统计</div>
            <div>{{ insurance }}</div>
          </div>
        </div>
      </el-col>
      <el-col :xs="24" :sm="12" :md="12" :lg="6" style="padding: 20px 10px;">
        <div class="count">
          <div class="content">
            <div>保险到期人员统计</div>
            <div>{{ unIsured }}</div>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { deliiveryType, underInsurance, uninsured } from '../../api/home';

export default {
    data() {
        return {
          // 兼职 全职人员统计 全职
          fullTime: '',
          // 兼职 全职人员统计 兼职
          partTime: '',
          // 保险到期 在保人员统计 在保
          insurance: '',
          // 保险到期 在保人员统计 到期
          unIsured: ''
        }
    },
    mounted() {
      this.deliiveryType();
      this.underInsurance();
      this.uninsured();
    },
    methods: {
        async deliiveryType() {
          try {
            const data = await deliiveryType();
            this.fullTime = data.fullTime;
            this.partTime = data.partTime;
          } catch (error) {
              console.log("error:", error)
          }
        },
        async underInsurance() {
          try {
            const data = await underInsurance();
            this.insurance = data;
          } catch (error) {
              console.log("error:", error)
          }
        },
        async uninsured() {
          try {
            const data = await uninsured();
            this.unIsured = data;
          } catch (error) {
              console.log("error:", error)
          }
        },
    }
}
</script>

<style lang="scss" scoped>
.home {
  .count {
    padding: 20px;
    border-radius: 8px;
    background-color: #409EFF;
    color: #FFFFFF;
    background-image: url(../../assets/icons/count.png);
    background-repeat: no-repeat;
    background-position: 20px center;
    background-size: 60px;
    .image {
      width: 50px;
    }
    .content {
      padding: 10px 10px 10px 80px;
      div:nth-child(1) {
        padding-bottom: 10px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }
  
}
</style>